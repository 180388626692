<template>
    <div class="row">
        <div class="col-md-12">
            <button style="" @click="$router.push('coloradd')" class="btn btn-primary">Add</button>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="color in Colors" :key="color.key">
                        <td>{{ color.name }}</td>
                        <td>
                            <router-link :to="{name: 'colorEdit', params: { id: color.key }}" class="btn btn-primary">Edit
                            </router-link>
                            <button @click.prevent="deleteColor(color.key)" class="btn btn-danger">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { db } from '../firebaseDb';
    export default {
        data() {
            return {
                Colors: [],
                currentTenant: "",
                service: {},
                serviceid: "",
                newCem: {},
                documents: [],
            }
        },
        created() {
            this.currentTenant = this.$tenant;
            this.updateservices();
        },
        methods: {
            updateservices() {
                db.collection('colors').orderBy('deleted').orderBy('name').where("tenant", "==", this.currentTenant).where("deleted", "!=", true).onSnapshot((snapshotChange) => {
                    this.Colors = []
                    snapshotChange.forEach(doc => {
                        this.Colors.push ({
                            key: doc.id,
                            name: doc.data().name,
                        })
                    })
                }) 
                // db.collection('services').get().then((snapshot) => {
                //     snapshot.docs.forEach(doc => {
                //         var newCem = doc.data();
                //         if(newCem.dod == null) {
                //             newCem.dod = "";
                //             db.collection("services").doc(doc.id).update(newCem).then(() => {
                //             console.log("Cemetery deleted!");
                //             })
                //         }
                //     })
                // }) 
            },
            deleteColor(id){
              if (window.confirm("Do you really want to delete?")) {
                this.newCem = this.Colors.find(x => x.key === id)
                console.log(this.newCem)
                this.newCem.deleted = true;
                db.collection("colors").doc(id).update(this.newCem).then(() => {
                    console.log("Cemetery deleted!");
                })
                .catch((error) => {
                    console.error(error);
                })
              }
            }
        }
    }
</script>

<style>
    .false {
        background-color: red;
    }
    .btn-primary {
        margin-right: 12px;
    }
</style>